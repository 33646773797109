<template>
	<div class="flex-col">
		<span v-if="devMode" class="mru-id">{{ mru.id }}</span>
		<h2 class="edit" title="Click to edit title" @click="editBasicDetailsModal()">{{ mru.name }}</h2>
		<p class="edit" title="Click to edit location" @click="editBasicDetailsModal()">{{ mru.location }}</p>
		<button class="btn" @click="viewDetailsModal()" title="View Details">View Details</button>
		<div class="pointer">
			<label :for="`enabled${index}`" :title="isEnabled ? 'Click to disable' : 'Click to enable'">Enabled</label>
			<input
				type="checkbox"
				:name="`Enabled${index}`"
				:id="`enabled${index}`"
				:title="isEnabled ? 'Click to disable' : 'Click to enable'"
				:checked="isEnabled"
				v-model="isEnabled"
			/>
		</div>
		<span v-if="!mru.isEnabled" class="general-status disabled" title="MRU Disabled"></span>
		<span v-else class="general-status" :title="getClassName()" :class="getClassName()"></span>
	</div>
</template>

<script>
export default {
	name: "MruStatusInstance",
	inheritAttrs: false,
	props: {
		mru: Object,
		index: Number,
	},
	data() {
		return {
			errorStates: ["Offline", "NotUsed", "Good", "NeedsAttendant", "Error"],
			stateClassNames: {
				Offline: "offline",
				NotUsed: "not-used",
				Good: "good",
				NeedsAttendant: "needs-attendant",
				Error: "error",
			},
			isEnabled: this.mru.isEnabled,
			checkedIn: Object.keys(this.mru.mruStatus).length > 0,
		};
	},
	watch: {
		isEnabled() {
			if (this.mru.isEnabled !== this.isEnabled) {
				this.dispatchMruEnable();
			}
		},
		mru: {
			handler() {
				this.isEnabled = this.mru.isEnabled;
				this.checkedIn = Object.keys(this.mru.mruStatus).length > 0;
			},
			deep: true,
		},
	},
	methods: {
		getClassName() {
			let className = "";
			if (Object.keys(this.mru.mruStatus).length > 0) {
				className = this.errorStates.includes(this.mru.mruStatus?.generalStatus)
					? this.stateClassNames[this.mru.mruStatus.generalStatus]
					: "";
			} else {
				className = "status-unknown";
			}
			return className;
		},
		dispatchMruEnable() {
			if (this.mru.isEnabled) {
				let confirmDisable = confirm(`Are you sure you want to disable ${this.mru.name}`);
				if (!confirmDisable) {
					this.isEnabled = this.mru.isEnabled;
					// Need to use vanilla JS for the checkbox to update when cancelling.
					// For some reason the Vue v-model is not updating the checkbox element to checked.
					// The v-model is correct but the HTML element's property doesn't match.
					let checkbox = document.getElementById(`enabled${this.index}`);
					checkbox.checked = this.isEnabled;
					return;
				}
			}
			this.mru.isEnabled = this.isEnabled;
			this.eventBus.emit("updateMruEnabled", this.mru);
		},
		editBasicDetailsModal() {
			this.eventBus.emit("openEditMruDetailsModal", this.mru);
		},
		viewDetailsModal() {
			this.eventBus.emit("openMruDetailsModal", this.mru);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.general-status {
	margin: 15px auto;
}

.general-status::after {
	content: "";
	font-weight: bold;
	color: white;
	padding: 11px 15px;
	/* line-height: 1em; */
	border-radius: 100%;
	height: 40px;
	width: 40px;
	border: 1px #000 solid;
}

.disabled::after {
	content: "\274C";
	background-color: #000;
	border-radius: 8px;
	padding: 10px;
}

.good::after {
	content: "\2713";
	background-color: green;
}

.error::after,
.warn::after,
.needs-attendant::after,
.status-unknown::after {
	content: "⚠️";
	font-size: 1.375em;
	padding: 6px 8px 12px;
}

.error::after {
	background-color: #f00;
}

.warn::after,
.needs-attendant::after,
.status-unknown::after {
	background-color: yellow;
}

.flex-col {
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.pointer *,
.edit {
	cursor: pointer;
}

.edit::after {
	content: "\270F";
	transform: rotate(135deg);
	position: absolute;
	color: #9d9d9d;
	transition: color 0.2s;
}

.mru-id {
	user-select: all;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.edit::after {
		transform: rotate(90deg);
	}
}

.edit:hover::after {
	/* content: "\270F";
	transform: rotate(135deg);
	position: absolute; */
	color: #000;
}
</style>
